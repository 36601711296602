<template>
  <div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
      v-if="showData"
    >
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records">
          <vs-td>
            <div class="flex flex-col items-center gap-3">
              <vx-tooltip text="Confirm" style="width: fit-content">
                <vs-button
                  color="primary"
                  icon-pack="feather"
                  icon="icon-check"
                  type="line"
                  @click="handleShow(tr, indextr)"
                />
              </vx-tooltip>

              <vx-tooltip text="Edit" style="width: fit-content">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-edit"
                  @click="handleEdit(tr)"
                />
              </vx-tooltip>

              <vx-tooltip text="Print" style="width: fit-content">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-printer"
                  @click="handlePrint(tr)"
                />
              </vx-tooltip>
            </div>
          </vs-td>
          <vs-td>
            <sr_number_display
              :srNumber="
                tr.debitNotes.PurchaseReturnCode
                  ? tr.debitNotes.PurchaseReturnCode
                  : tr.debitNotes.SrCode
              "
          /></vs-td>
          <vs-td>{{ tr.debitNotes.Code }}</vs-td>
          <vs-td>
            Name : {{ tr.debitNotes.SupplierName }}
            <br />
            Code : {{ tr.debitNotes.SupplierCode }}
            <br />
          </vs-td>
          <vs-td>
            Value : {{ priceFormat(tr.debitNotes.DNValue) }}
            <br />
            Tax : {{ priceFormat(tr.debitNotes.TaxValue) }}
            <br />
            Total : {{ priceFormat(tr.debitNotes.TotalDnValue) }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>

    <vs-prompt
      title="Confirmation"
      color="primary"
      @accept="confirmDN(selectedData)"
      @cancel="handleClose"
      @close="handleClose"
      :buttons-hidden="false"
      :active.sync="isPromptShow"
    >
      <div class="con-exemple-prompt">
        Debit Note
        <br />
        Are you sure to confirm debit note
        <b>{{
          this.selectedData.debitNotes ? this.selectedData.debitNotes.Code : ""
        }}</b>
        ?
      </div>
      <!-- <div v-else>
        <div class="con-exemple-prompt">
          Debit Note
          <br />
          Are you sure to confirm debit note
          <b>{{
            this.selectedData.debitNotes
              ? this.selectedData.debitNotes.Code
              : ""
          }}</b>
          ?
        </div>
        <div class="con-exemple-prompt" color="warning">
          <b>Approval Required</b>
          <br />
          The following Quantity and Value for the following items have been
          changed!
          <br />
          <ul>
            <li v-for="item in selectedItemNames" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
      </div> -->
    </vs-prompt>
  </div>
</template>

<script>
import moment from "moment";
import Detail from "./detail.vue";
import SRNumber from "./sr_number_display.vue";
export default {
  components: {
    detail: Detail,
    sr_number_display: SRNumber,
  },
  created() {
    this.$store.watch(
      (state) => state.operatingUnit.filter.selectedTerritories,
      () => {
        this.reloadData(this.params);
      }
    );
  },
  props: {
    grrDate: {
      type: Object,
      default: () => ({}),
    },
    grrType: {
      type: Object,
      default: () => ({}),
    },
    supplierCodes: {
      type: Array,
      default: () => [],
    },
    territoryIDs: {
      type: Array,
      default: () => [],
    },
    supplierCodeOptions: {
      type: Array,
      default: () => [],
    },
    showData: {
      type: Boolean,
      default: false,
    },
    srNumbers: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    grrDate() {
      this.reloadData(this.params);
    },
    grrType() {
      this.reloadData(this.params);
    },
    supplierCodes() {
      this.reloadData(this.params);
    },
    srNumbers() {
      this.reloadData(this.params);
    },
    territoryIDs() {
      this.reloadData(this.params);
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "",
        },
        {
          text: "SR Number",
        },
        {
          text: "Code",
        },
        {
          text: "Supplier",
        },
        {
          text: "Amount",
        },
      ],
      responseData: {},
      selectedData: {},
      isPromptShow: false,
      isNeedApproval: false,
      selectedItemNames: [],
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      sr_ids: [],
      pr_ids: [],
    };
  },
  methods: {
    reloadData(params) {
      params = {
        ...params,
        status: 7, //draft
        type: "Return",
        grr_start_date:
          Object.keys(this.grrDate).length && this.grrDate.startDate
            ? moment(this.grrDate.startDate).format("YYYY-MM-DD")
            : "",
        grr_end_date:
          Object.keys(this.grrDate).length && this.grrDate.endDate
            ? moment(this.grrDate.endDate).format("YYYY-MM-DD")
            : "",
        grr_type: this.grrType.name === "All" ? undefined : this.grrType.name,
        territory_ids:
          this.$store.getters["operatingUnit/getSelectedTerritoryId"],
      };

      if (this.supplierCodes.some((v) => v.supplier_code !== "All")) {
        params.scds = this.supplierCodes.map((v) => v.supplier_code);
      }

      if (this.srNumbers.some((v) => v.code !== "All")) {
        params.srns = this.srNumbers.map((v) => v.code);
      }

      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/return", { params })
        .then((res) => {
          if (res.code < 299) {
            this.$vs.loading.close();
            this.responseData = res.data.records !== null ? res.data : {};
            // this.responseData.lines.forEach((line) => {
            //   if (line.PurchaseReturnID && line.PurchaseReturnID > 0) {
            //     this.pr_ids.push(line.PurchaseReturnID);
            //   } else {
            //     this.sr_ids.push(line.SupplierReturnID);
            //   }
            // });

            // this.pr_ids = [...new Set(this.pr_ids)];
            // this.sr_ids = [...new Set(this.sr_ids)];
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: res.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: err.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        });
    },
    handleShow(tr, index) {
      this.selectedData = tr;
      this.isPromptShow = true;
    },

    // displaySRNumber(srNumber) {
    //   if (srNumber.includes(",")) {
    //     return srNumber.split(",").join(", ");
    //   } else {
    //     return `SR${srNumber}`;
    //   }
    // },

    handleEdit(tr) {
      this.selectedData = tr;
      const id = btoa(tr.debitNotes.ID);
      this.$router.push({
        path: `/account-payable/dn-with-or-without-po-ref/dn-edit/${id}`,
      });
    },

    handlePrint(tr) {
      this.selectedData = tr;
      // this.$router.push({
      //   path: `/account-payable/dn-with-or-without-po-ref-draft/print-tax-return/${tr.debitNotes.ID}`,
      // });
      window.open(
        `/account-payable/dn-with-or-without-po-ref-draft/print-tax-return/${tr.debitNotes.ID}`,
        "_blank"
      );
    },

    handleClose() {
      this.isPromptShow = false;
      this.selectedData = {};
    },

    confirmDN(data) {
      if (
        !data.debitNotes.TaxInvoiceReferenceID ||
        !data.debitNotes.TaxInvoiceReferenceValue
      ) {
        if (data.debitNotes.TaxInvoiceReferenceRefText === "") {
          this.$vs.notify({
            title: "Error",
            text: "Please select Tax Invoice Reference first",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          this.$vs.loading.close();
          return;
        }
      }
      this.$vs.loading();
      this.$http
        .patch("/api/v1/debit-note/return/status", {
          id: data.debitNotes.ID,
          status: 1,
        })
        .then((res) => {
          if (res.code < 299) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: res.message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check-circle",
              position: "top-right",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: res.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: err.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        });
      this.isPromptShow = false;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
